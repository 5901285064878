<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isPersonalInfo" />
    <div v-else class="personal-info">
      <div class="cardError">
        <div
          v-if="cardError"
          class="pb-1 cardError"
          :class="cardHasError ? 'card-error-msg' : ''"
        >
          {{ cardHasError }}
        </div>
      </div>
      <CCard
        class="has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-3">
              <div class="avatar-upload">
                <div class="avatar-preview">
                  <div
                    v-if="
                      url === '/img/user_default_pic.png' && getProfilePhoto
                    "
                    :style="{ backgroundImage: 'url(' + getProfilePhoto + ')' }"
                  />
                  <div
                    v-else-if="url !== getProfilePhoto"
                    :style="{ backgroundImage: 'url(' + url + ')' }"
                  />
                </div>
                <div class="avatar-edit">
                  <input
                    id="imageUpload"
                    type="file"
                    ref="profilefileref"
                    name="image"
                    accept="image/*"
                    @change="selectedFile"
                  />
                  <label
                    for="imageUpload"
                    class="p-2 text-primary font-weight-bold"
                    >Update Photo</label
                  >
                </div>
                <div v-if="isImageSelected">
                  <UploadDocument
                    class="mt-4"
                    :fileData="fileData"
                    @closeModal="closeModal"
                    :isProfilePic="true"
                    v-on="$listeners"
                  />
                </div>
              </div>
              <p
                class="small pl-3 pt-1"
                style="font-size: 10px; color: red; font-weight: 500"
              >
                You may use your mobile or laptop camera to upload your photo!
              </p>
            </div>
            <div class="col-md-9 responsive-margin">
              <CRow>
                <div class="d-flex justify-content-between" style="width: 100%">
                  <div class="mt-2 ml-2">
                    <h5 class="main-title pb-3">Personal Information</h5>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button
                      name="save"
                      class="hand-icon action-button mr-2"
                      v-c-tooltip="'Save'"
                      @click="updatepersonalInfo"
                    >
                      <CIcon
                        name="cil-save"
                        class="active"
                        style="margin-right: 20px"
                      />
                    </button>
                    <button
                      name="cancel"
                      class="hand-icon action-button mr-3"
                      v-if="getMultiFormEdited['personalInformation']"
                      v-c-tooltip="'Cancel'"
                      @click="resetPersonalData(), clearFields()"
                    >
                      <CIcon name="cil-x-circle" style="margin-right: 20px" />
                    </button>
                  </div>
                </div>
                <ValidationObserver
                  ref="personalInfo"
                  v-slot="{ handleSubmit }"
                >
                  <form
                    id="personalInfo"
                    name="personalInfo"
                    @submit.prevent="handleSubmit(onSubmitStep1)"
                  >
                    <fieldset :disabled="isDisabled">
                      <CRow>
                        <CCol md="6">
                          <CRow
                            class="row mb-3"
                            :class="profile.title_id ? '' : 'requiredfield'"
                          >
                            <label class="required col-lg-12 col-md-12"
                              >Title</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <Select
                                  name="title_id"
                                  @input="handleChangeSelect"
                                  :value="profile.title_id"
                                  :options="
                                    options && options['titles']
                                      ? options['titles']
                                      : []
                                  "
                                  :taggable="false"
                                  :multiple="false"
                                  :clearable="false"
                                  :error="errors[0]"
                                  :disabled="isDisabled"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                        <CCol md="6">
                          <CRow
                            class="mb-3"
                            :class="profile.first_name ? '' : 'requiredfield'"
                          >
                            <label
                              name="lbl_first_name"
                              class="required col-lg-12 col-md-12"
                              >First Name</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="required|alpha_spaces"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="first_name"
                                  :value="profile.first_name"
                                  @input="handleInput"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="6">
                          <CRow class="row mb-3">
                            <label class="col-lg-12 col-md-12"
                              >Middle Name(s)</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="alpha_spaces"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="middle_name"
                                  :value="profile.middle_name"
                                  @input="handleInput"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                        <CCol md="6">
                          <CRow
                            class="mb-3"
                            :class="profile.surname ? '' : 'requiredfield'"
                          >
                            <label
                              name="lbl_surname"
                              class="required col-lg-12 col-md-12"
                              >Last Name</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="required|alpha_spaces"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="surname"
                                  :value="profile.surname"
                                  @change="handleInput"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="6">
                          <CRow class="mb-3">
                            <label
                              name="lbl_known_as"
                              class="col-lg-12 col-md-12"
                              >Known as</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="alpha_spaces"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="known_as"
                                  :value="profile.known_as"
                                  @change="handleInput"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                        <CCol md="6">
                          <CRow
                            class="mb-3"
                            :class="profile.dob ? '' : 'requiredfield'"
                          >
                            <label
                              name="lbl_dob"
                              class="required col-lg-12 col-md-12"
                              >Date of Birth</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                              <ValidationProvider
                                name="dob"
                                rules="required|date_validate|no_future_date"
                                v-slot="{ errors }"
                              >
                                <DateInput
                                  :disabled="isDisabled"
                                  name="dob"
                                  :value="profile.dob"
                                  @change="handleDatePickerChange"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="6">
                          <CRow
                            class="mb-3"
                            :class="profile.gender_id ? '' : 'requiredfield'"
                          >
                            <label
                              name="lbl_gender_id"
                              class="required col-lg-12 col-md-12"
                              >Gender</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <Select
                                  name="gender_id"
                                  :value="profile.gender_id"
                                  :options="
                                    options && options['gender']
                                      ? options['gender']
                                      : []
                                  "
                                  @input="handleChangeSelect"
                                  :taggable="false"
                                  :multiple="false"
                                  :clearable="false"
                                  :disabled="isDisabled"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                        <CCol md="6">
                          <CRow
                            class="mb-3"
                            :class="
                              profile.nationality_ids ? '' : 'requiredfield'
                            "
                          >
                            <label
                              name="lbl_nationality_id"
                              class="required col-lg-12 col-md-12"
                              >Nationality</label
                            >
                            <div class="col infoicon-multi-select">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <Select
                                  name="nationality_ids"
                                  @input="handleChangeMultiSelectAsArray"
                                  :value="profile.nationality_ids"
                                  :options="
                                    options && options['nationality']
                                      ? options['nationality']
                                      : []
                                  "
                                  :taggable="false"
                                  :multiple="true"
                                  option_label="nationality"
                                  :clearable="true"
                                  :disabled="isDisabled"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                            <div
                              class="position-relative multi-select-icon-div"
                            >
                              <CIcon
                                name="cil-info"
                                class="hinticon-multi-select"
                                v-c-tooltip="'Select one or more'"
                              />
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="6">
                          <CRow class="mb-3">
                            <label
                              name="lbl_passport_no"
                              class="col-lg-12 col-md-12"
                              >Passport Number</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <TextInput
                                name="passport_number"
                                :value="profile.passport_number"
                                @change="handleInput"
                              />
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="6">
                          <CRow
                            class="mb-3"
                            :class="profile.birth_place ? '' : 'requiredfield'"
                          >
                            <label
                              name="lbl_birthplace"
                              class="required col-lg-12 col-md-12"
                              >Birthplace</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <Select
                                  name="birth_place"
                                  :value="profile.birth_place"
                                  :options="
                                    options && options['birth_place']
                                      ? options['birth_place']
                                      : []
                                  "
                                  :taggable="false"
                                  :multiple="false"
                                  :clearable="false"
                                  @input="handleChangeSelect"
                                  :error="errors[0]"
                                  :disabled="isDisabled"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                        <CCol md="6">
                          <CRow
                            class="mb-3"
                            :class="
                              profile.marital_status ? '' : 'requiredfield'
                            "
                          >
                            <label
                              name="lbl_marital_status"
                              class="col-lg-12 col-md-12"
                              >Marital Status</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <RadioButton
                                name="marital_status"
                                :value="profile.marital_status"
                                :options="
                                  options && options['marital_status']
                                    ? options['marital_status']
                                    : []
                                "
                                label="marital_status"
                                @change="handleChangeRadio"
                              />
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <div
                        v-if="
                          !isNonClinicAdmin &&
                          profile.marital_status == 'Married'
                        "
                      >
                        <CRow>
                          <CCol md="6">
                            <CRow class="mb-3">
                              <label
                                name="lbl_married"
                                class="col-lg-12 col-md-12"
                                >Marital Partner Also Seeking Clinical
                                Job?</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <RadioButton
                                  name="marital_partner_seeking_job"
                                  :value="profile.marital_partner_seeking_job"
                                  :options="
                                    options && options['boolean']
                                      ? options['boolean']
                                      : []
                                  "
                                  label="marital_partner_seeking_job"
                                  @change="handleChangeRadio"
                                />
                              </div>
                            </CRow>
                          </CCol>
                          <CCol
                            md="6"
                            v-if="profile.marital_partner_seeking_job == true"
                          >
                            <CRow class="mb-3">
                              <label
                                name="lbl_spouse_registered"
                                class="col-lg-12 col-md-12"
                                >Spouse Registered?</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <RadioButton
                                  name="spouse_registered"
                                  :value="profile.spouse_registered"
                                  :options="
                                    options && options['boolean']
                                      ? options['boolean']
                                      : []
                                  "
                                  label="spouse_registered"
                                  @change="handleChangeRadio"
                                />
                              </div>
                            </CRow>
                          </CCol>
                        </CRow>
                        <CRow
                          v-if="profile.marital_partner_seeking_job == true"
                        >
                          <CCol md="6">
                            <CRow class="mb-3">
                              <label
                                name="lbl_name_of_spouse"
                                class="col-lg-12 col-md-12"
                                >Name of Spouse</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <ValidationProvider
                                  rules="alpha_spaces"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    name="name_of_spouse"
                                    :value="profile.name_of_spouse"
                                    label="name_of_spouse"
                                    @input="handleInput"
                                    :error="errors[0]"
                                  ></TextInput>
                                </ValidationProvider>
                              </div>
                            </CRow>
                          </CCol>
                          <CCol md="6">
                            <CRow class="mb-3">
                              <label
                                name="lbl_spouse_email"
                                class="col-lg-12 col-md-12"
                                >Spouse Email</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <ValidationProvider
                                  rules="email"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    name="spouse_email"
                                    :value="profile.spouse_email"
                                    label="spouse_email"
                                    @input="handleInput"
                                    :error="errors[0]"
                                  ></TextInput>
                                </ValidationProvider>
                              </div>
                            </CRow>
                          </CCol>
                        </CRow>
                      </div>
                      <CRow>
                        <CCol md="6">
                          <CRow class="mb-3">
                            <label
                              name="lbl_dependents"
                              class="col-lg-12 col-md-12"
                              v-if="profile.dependents > 0"
                            >
                              Dependents (under 18)
                              <strong
                                >Selected - {{ profile.dependents }}</strong
                              >
                            </label>
                            <label class="col-lg-12 col-md-12" v-else>
                              Dependents (under 18)
                              <strong>Selected - 0</strong>
                            </label>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <v-slider
                                name="dependents"
                                :min="minDependants"
                                :max="maxDependants"
                                :value="profile.dependents"
                                @change="onChange"
                                :disabled="isDisabled"
                              />
                            </div>
                          </CRow>
                        </CCol>
                        <CCol md="6">
                          <CRow class="mb-3">
                            <label
                              name="lbl_relocate_family"
                              class="col-lg-12 col-md-12"
                              >Will You Relocate Your Family?</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <RadioButton
                                name="relocate_family"
                                :value="profile.relocate_family"
                                :options="
                                  options && options['boolean']
                                    ? options['boolean']
                                    : []
                                "
                                label="relocate_family"
                                @change="handleChangeRadio"
                              />
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="6">
                          <CRow class="mb-3">
                            <label
                              name="lbl_is_private"
                              class="col-lg-12 col-md-12"
                              >Public or Private Profile?</label
                            >
                            <div class="col-lg-12 col-md-12">
                              <RadioButton
                                name="is_private"
                                :value="profile.is_private"
                                :options="
                                  options && options['isPrivate']
                                    ? options['isPrivate']
                                    : []
                                "
                                label="is_private_user"
                                @change="handleChangeRadio"
                              />
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                    </fieldset>
                  </form>
                </ValidationObserver>
              </CRow>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
      <CToaster v-if="isFileError" :autohide="3000" position="top-right">
        <CToast :show="true" class="bg-danger text-white"
          >File too Big, please select a file less than 2mb</CToast
        >
      </CToaster>
    </div>
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import m from "moment";
import ProfileSection from "./ProfileSection";
import { min } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import { BASE_URL } from "@/service_urls";
import { extend } from "vee-validate";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import {
  required,
  numeric,
  alpha_spaces,
  alpha_num,
  max_value,
  min_value,
} from "vee-validate/dist/rules";
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("required", { ...required, message: "This field is required" });
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Only Alphabets & Spaces are allowed",
});
extend("alpha_num", {
  ...alpha_num,
  message: "Only Alphabets & numbers are allowed",
});
extend("min_value", { ...min_value, message: "Enter a valid Candidate id" });
extend("max_value", { ...max_value, message: "Enter a valid Candidate id" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
export default {
  mixins: [ProfileSection],
  components: {
    UploadDocument,
  },
  props: {
    profilePhoto: {},
  },
  data() {
    return {
      isImageSelected: false,
      fileData: {},
      isFileError: false,
      isDisabled: false,
      payload: {},
      url: "/img/user_default_pic.png",
      profile: {},
      errors: [],
      minDependants: 0,
      maxDependants: 10,
      cardHasError: "",
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "getUploadedDocument",
      "candidateProfile",
      "titles",
    ]),
    options() {
      return {
        gender: this.gender || [],
        birth_place: this.locationCountryInfo || [],
        nationality: this.nationalityOptions || [],
        marital_status: [
          { id: "Married", label: "Married" },
          { id: "Single", label: "Single" },
        ],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
          { id: null, label: "N/A" },
        ],
        titles: this.titles || [],
        isPrivate: [
          { id: true, label: "Make my profile Private" },
          { id: false, label: "Make my profile Public" },
        ],
      };
    },
    nationalityOptions() {
      return (
        (this.nationalityCountryInfo &&
          this.profile &&
          this.profile["nationality_ids"] &&
          this.nationalityCountryInfo.filter((val) => {
            return !this.profile["nationality_ids"].some(
              ({ code }) => code === val.code
            );
          })) ||
        this.nationalityCountryInfo
      );
    },
    document() {
      return (
        this.getUploadedDocument &&
        this.getUploadedDocument.length &&
        this.getUploadedDocument.map(
          ({
            document_type_id,
            uuid_link_to_doc,
            document_name,
            document_type,
          }) => {
            return {
              link: uuid_link_to_doc,
              type: document_type_id,
              name: document_name,
            };
          }
        )
      );
    },
    getProfilePhoto() {
      const profilePic =
        this.document &&
        this.document.length &&
        this.document
          .filter((e) => e.type === 2)
          .map((data) => {
            return data.link.replace("/api/v1/", "");
          });
      return (
        profilePic &&
        profilePic.length &&
        appendAccessToken(`${BASE_URL}/${profilePic}`)
      );
    },
    isPersonalInfo() {
      if (Object.keys(this.candidateProfile).length) {
        return this.setPersonalData(this.candidateProfile);
      }
      return false;
    },
    isNonClinicAdmin() {
      return this.candidateProfile.candidate_type_id == 6 ? true : false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.personalInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        if (this.getMultiFormEdited.fieldValidateError) {
          this.cardHasError = "Please fill mandatory fields";
        }
        let el = this.$el.getElementsByClassName("cardError")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.fetchUploadedDocument();
        this.url = this.profilePhoto.value.croppedImageSrc;
        this.$refs.profilefileref.value = "";
        this.isImageSelected = false;
        this.fileData = {};
      }
    },
  },
  mounted() {
    this.fetchUploadedDocument();
    const isValid = this.$refs.personalInfo.validate().then((res) => {
      res ? null : this.multiFormEdited({ fieldValidateError: true });
    });
  },
  methods: {
    ...mapActions([
      "fetchUploadedDocument",
      "updateCandidate",
      "fetchUser",
      "getCandidateIdByMail",
    ]),
    setPersonalData(profileData) {
      this.profile = {
        customer_uid: profileData?.customer_uid,
        candidate_uid: profileData?.candidate_uid,
        title_id:
          profileData.title !== null
            ? {
                code: profileData?.title?.title_id,
                label: profileData?.title?.title,
              }
            : null,
        first_name: profileData?.first_name,
        middle_name:
          profileData.middle_name !== null ? profileData.middle_name : null,
        surname: profileData?.surname,
        known_as: profileData?.known_as,
        dob: profileData.dob ? profileData.dob : null,
        gender_id:
          profileData.gender != null
            ? {
                code: profileData?.gender?.gender_id,
                label: profileData?.gender?.gender,
              }
            : null,
        nationality_ids:
          profileData.nationality_ids !== null
            ? this.locationCountryInfo.filter(({ code }) =>
                profileData.nationality_ids.includes(code)
              )
            : null,
        birth_place:
          profileData.birth_place_id !== null
            ? {
                code: profileData?.birth_place?.country_id,
                label: profileData?.birth_place?.country_name,
              }
            : null,
        marital_status: profileData?.marital_status || null,
        dependents: profileData?.dependents,
        marital_partner_seeking_job: profileData?.marital_partner_seeking_job,
        spouse_registered: profileData?.spouse_registered,
        name_of_spouse: profileData?.name_of_spouse,
        spouse_email: profileData?.spouse_email,
        relocate_family: profileData?.relocate_family,
        passport_number: profileData?.passport_number,
        is_private: profileData?.is_private,
      };
      return this.profile;
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        this.isFileError = false;
        const file = event.target.files[0];
        this.isImageSelected = true;
        this.fileData = file;
      } else {
        this.$refs.profilefileref.value = "";
        this.isFileError = true;
      }
    },
    closeModal() {
      this.$refs.profilefileref.value = "";
      this.fileData = {};
      this.isImageSelected = false;
    },
    async updatepersonalInfo() {
      this.$emit("tooltipHide");
      this.cardHasError = "";
      const isValid = await this.$refs.personalInfo.validate();
      if (!isValid) {
        this.cardHasError = "please fill mandatory fields!";
        return;
      }
      if (this.profile?.marital_status == "Single") {
        this.profile.marital_partner_seeking_job = null;
        this.profile.spouse_registered = null;
        this.profile.name_of_spouse = null;
        this.profile.spouse_email = null;
      }
      let appendAction = [];
      if (this.profile?.spouse_registered && this.profile?.spouse_email) {
        appendAction = this.getCandidateIdByMail(
          this.profile.spouse_email
        ).then((res) => {
          if (res.status == 200) {
            this.profile.spouse_profile_id = res.data;
            if (res.data == this.candidateProfile.candidate_uid) {
              this.cardHasError =
                "Spouse Mail ID should be different from your mail ID";
            }
          } else {
            this.cardHasError = "No User found for spouse E-Mail ID";
          }
        });
      }
      Promise.all([appendAction]).then((res) => {
        if (this.cardHasError) return;
        this.payload = {
          ...this.payload,
          candidate_uid: this.profile?.candidate_uid,
          first_name: this.profile?.first_name,
          surname: this.profile?.surname,
          known_as: this.profile?.known_as,
          dob: this.profile?.dob,
          gender_id: this.profile?.gender_id?.code,
          nationality_ids:
            this.profile?.nationality_ids.map(({ code }) => code) || [],
          birth_place_id: this.profile?.birth_place?.code,
          marital_status: this.profile?.marital_status,
          dependents: this.profile?.dependents,
          marital_partner_seeking_job:
            this.profile.marital_partner_seeking_job !== ""
              ? this.profile.marital_partner_seeking_job
              : null,
          spouse_registered:
            this.profile.spouse_registered !== ""
              ? this.profile.spouse_registered
              : null,
          name_of_spouse:
            this.profile.name_of_spouse !== ""
              ? this.profile.name_of_spouse
              : null,
          spouse_email:
            this.profile.spouse_email !== "" ? this.profile.spouse_email : null,
          spouse_profile_id: this.profile.spouse_registered
            ? this.profile?.spouse_profile_id || null
            : null,
          relocate_family:
            this.profile.relocate_family !== ""
              ? this.profile.relocate_family
              : null,
          is_private: this.profile?.is_private || false,
        };
        this.savePersonalInfo(this.payload);
      });
    },
    savePersonalInfo(data) {
      const { customer_uid } = this.profile;
      let appendAction = this.updateCandidate({ data, customer_uid });
      Promise.all([appendAction]).then((res) => {
        this.fetchUser();
        this.clearFields();
        let el = this.$parent.$el.getElementsByClassName("border-top")[0];
        el.scrollIntoView({ iniline: "end" });
      });
    },
    resetPersonalData() {
      this.setPersonalData(this.candidateProfile);
    },
    clearFields() {
      this.cardHasError = "";
      this.multiFormEdited({ personalInformation: false });
      this.multiFormEdited({ fieldValidateError: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
    onChange: debounce(function (value) {
      Vue.set(this.profile, "dependents", value);
      Vue.set(this.payload, "dependents", value);
      this.multiFormEdited({ personalInformation: true });
    }, 500),
    handleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      Vue.set(this.profile, name, checked);
      this.payload = {
        ...this.payload,
        [name]: checked,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.hand-icon {
  cursor: pointer;
}
</style>
